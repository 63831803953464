import mitt from 'mitt'
import store from '@/core/store'
import Router from '@/core/router'
import Localization from '@/core/localization'
import plugins from '@/plugins'
import resources from '@/core/resources'

const getOverrideResource = async function ()
{
	const url = `${process.env.BASE_URL}config/override-language-resources.json?v=${Date.now()}`
	const response = await fetch(url);
	const ols = await response.json();

	return ols
}

const getServers = async () =>
{
	const url = `${process.env.BASE_URL}config/server.json?v=${Date.now()}`
	const response = await fetch(url);
	const config = await response.json();

	return config[process.env.NODE_ENV].servers
}

const assetPrivateLink = (key, isInline, addToken) =>
{
	const servers = store.state.server.servers;
	var serverConfig = servers.find(x => x.name == 'asset')
	if (!serverConfig)
	{
		serverConfig = servers.find(x => x.name == 'public')
	}

	const action = isInline ? 'show' : 'download';

	if (addToken)
	{
		const token = store.state.client.token;
		return `${serverConfig.host}asset/file/${action}/${key}?access_token=${token}`
	}

	return `${serverConfig.host}asset/file/${action}/${key}`
}

const assetPublicLink = (path) =>
{
	const servers = store.state.server.servers;
	var serverConfig = servers.find(x => x.name == 'asset')
	if (!serverConfig)
	{
		serverConfig = servers.find(x => x.name == 'public')
	}

	path = path.split('\\').join('/')
	return `${serverConfig.host}uploaded/${path}`;

}

const assetDownload = (key, fileName) => new Promise((resolve, reject) =>
{
	const url =
		assetPrivateLink(key, false, true)

	fetch(url).then(res => res.blob()).then(blob =>
	{
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = fileName;
		link.click();

		resolve(true)
	}).catch((e) => reject(e));
})


const getAppConfig = async () =>
{
	const url = `${process.env.BASE_URL}config/config.json?v=${Date.now()}`
	const response = await fetch(url);
	const config = await response.json();

	return config;
}

const launch = async function (app)
{
	const language = localStorage.getItem("kaveh.app.language") || process.env.VUE_APP_LANGUEGE;
	const direction = localStorage.getItem("kaveh.app.direction") || process.env.VUE_APP_DIRECTION;
	const color = localStorage.getItem("kaveh.app.color") || process.env.VUE_APP_COLOR;
	const layout = localStorage.getItem("kaveh.app.layout") || process.env.VUE_APP_LAYOUT;

	const overrideResource = await getOverrideResource();
	const servers = await getServers();
	const appConfig = await getAppConfig();

	await store.dispatch('app/setLanguage', language);
	await store.dispatch('app/setLayout', layout);
	await store.dispatch('app/setColor', color);
	await store.dispatch('app/setDirection', direction);
	await store.dispatch('app/setResource', resources);
	await store.dispatch('app/setOverrideResource', overrideResource);
	await store.dispatch('app/setConfig', appConfig);
	await store.dispatch('client/setTokenFromStorage');
	await store.dispatch('client/setAccessKeysFromStorage');
	await store.dispatch('client/setBranchFromStorage');
	await store.dispatch('server/setServers', servers);

	app.config.globalProperties.$emitter = mitt();
	app.config.globalProperties.$plugins = plugins;
	app.config.globalProperties.$assetPrivateLink = assetPrivateLink;
	app.config.globalProperties.$assetPublicLink = assetPublicLink;
	app.config.globalProperties.$assetDownload = assetDownload;
	app.config.globalProperties.$loc = new Localization();

	const router =
		new Router([]).create();

	if (store.state.client.token)
	{
		window.$router = router;
	}

	window.$keys = {};

	app.use(store);
	app.use(router);
	app.mount('#app');
}

export default { launch }
