import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Localization from '../localization'
export default class Router
{
	constructor(modules)
	{
		this.modules = modules || [];
		this.loc = new Localization();
		this.layout = store.state.app.layout

		this.routes = [
			{
				path: '/',
				name: 'layout',
				redirect: to =>
				{
					return { name: 'home' };
				},
				component: () => import(`/src/views/${this.layout}/shareds/layout.vue`),
				meta: {
					requiresAuth: true,
					resourceKey: 'page.layout',

					menuConfig: {
						isVisible: true,
						isExpand: true,
						isBlank: true,
						alwaysIsExpand: true,
						iconName: 'bi-layout-wtf',
					}
				},
				children: [
					{
						name: 'home',
						path: 'home',
						component: () => import('@/views/default/home/home-layout.vue'),
						meta: {
							requiresAuth: false,
							resourceKey: 'page.home',
						}
					},
					{
						name: 'conversations',
						path: 'conversations',
						component: () => import('@/views/default/home/home-layout.vue'),
						meta: {
							requiresAuth: false,
							resourceKey: 'page.conversations',
						}
					},
					{
						name: 'interact',
						path: 'interact',
						component: () => import('@/views/default/interact/interact-layout.vue'),
						meta: {
							requiresAuth: false,
							resourceKey: 'page.interact',
						}
					},
					{
						name: 'setting',
						path: 'setting',
						component: () => import('@/views/default/setting/setting-layout.vue'),
						meta: {
							requiresAuth: false,
							resourceKey: 'page.setting',
						}
					},

					{
						name: 'notification',
						path: 'notification',
						component: () => import('@/views/default/notification/notification-layout.vue'),
						meta: {
							requiresAuth: false,
							resourceKey: 'page.notification',
						}
					},

					{
						name: 'pro',
						path: 'pro',
						component: () => import('@/views/default/pro/pro-layout.vue'),
						meta: {
							requiresAuth: false,
							resourceKey: 'page.pro',
						}
					}
				],
			},
			{
				name: 'auth',
				path: '/auth',
				component: () => import('@/views/default/auth/auth-base.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.auth',

					menuConfig: {
						visible: false
					}
				}
			},

			{
				name: 'page',
				path: '/page/:slug',
				component: () => import('@/views/default/page/page-dynamic.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.page',

					menuConfig: {
						visible: false
					}
				}
			},

			{
				name: 'authOidc',
				path: '/auth-oidc',
				component: () => import('@/views/default/auth/auth-oidc.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.auth',

					menuConfig: {
						visible: false
					}
				}
			},

			{
				name: 'notFound',
				path: '/:catchAll(.*)',
				component: () => import('@/views/not-found.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.notFound',

					menuConfig: {
						visible: false
					}
				}
			},
			{
				name: 'accessDenied',
				path: '/access-denied',
				component: () => import('@/views/access-denied.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.accessDenied',

					menuConfig: {
						visible: false
					}
				}
			},
			{
				name: 'deleteAccount',
				path: '/delete-account',
				component: () => import('@/views/delete-account.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.deleteAccount',

					menuConfig: {
						visible: false
					}
				}
			},
			{
				name: 'whatsNew',
				path: '/whats-new',
				component: () => import('@/views/whats-new.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.whatsNew',

					menuConfig: {
						visible: false
					}
				}
			}
		]

		for (var i = 0; i < modules.length; i++)
		{
			for (var ii = 0; ii < modules[i].routes.length; ii++)
			{
				this.routes[0].children.push(modules[i].routes[ii])
			}
		}
	}

	create()
	{
		const router = createRouter({
			history: createWebHistory(),
			routes: this.routes
		})

		var navigationInfo = null;
		router.options.history.listen((to, from, info) =>
		{
			navigationInfo = info;
		});

		router.beforeEach((to, from, next) =>
		{
			document.title =
				this.loc.fromResource(to.meta.resourceKey) + ' | ' +
				this.loc.fromResource('global.applicationName');

			if (to.matched.some(record => record.meta.requiresAuth))
			{
				const token = (store.state.client || {}).token;

				if (token == undefined)
				{
					next({
						path: "/auth",
						query: { redirect: to.fullPath }
					});

					return;
				}

				if (to.path != '/hub/person/change-password' && store.state.client.fcp)
				{
					next({
						path: "/hub/person/change-password"
					})

					return;
				}
			}

			if (navigationInfo && navigationInfo.direction === 'back')
			{
				next({
					path: "/home"
				})

				navigationInfo = null
			}

			next()
		})

		return router
	}
}
