<template>
	<router-view :key="$store.state.app.language" />
</template>
<script>
	export default {
		data()
		{
			return {
				version: '2.4.0',
				testMessage: ''
			}
		},
	}
</script>
<style>
	.kaveh-version {
		position: fixed;
		bottom: 2px;
		left: 2px;
		z-index: 10000;
	}

	[dir="ltr"] .kaveh-version {
		left: unset;
		right: 2px;
	}
</style>

