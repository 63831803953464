import { createApp } from 'vue'

import './assets/fonts/urbanist/urbanist.css'
import './assets/fonts/manrope/manrope.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import "toastify-js/src/toastify.css"

import './assets/css/app.css'
import 'swiper/css'
import './registerServiceWorker'

import App from './App.vue'
import launcher from './launcher'
import KavehPopup from './components/popup/popup'
import KavehCollapse from './components/collapse/collapse'
import KavehButton from './components/button/button'
import KavehIconBootstrap from './components/icon/icon-bootstrap'
import KavehContent from './components/content/content'
import KavehLoading from './components/common/common-loading'
import KavehConfirmDelete from './components/common/common-confirm-delete'
import KavehOverlay from './components/overlay/overlay'
import KavehUploader from './components/uploader/uploader-base'

import popper from 'vue3-popper'
import DatePicker from 'vue3-persian-datetime-picker'


const app = createApp(App)
app.component('kaveh-button', KavehButton)
app.component('kaveh-popup', KavehPopup)
app.component('kaveh-icon-bootstrap', KavehIconBootstrap)
app.component('kaveh-content', KavehContent)
app.component('kaveh-collapse', KavehCollapse)
app.component('kaveh-loading', KavehLoading)
app.component('kaveh-confirm-delete', KavehConfirmDelete)
app.component('kaveh-overlay', KavehOverlay)
app.component('kaveh-uploader', KavehUploader)
app.component('date-picker', DatePicker)
app.component('popper', popper)

launcher.launch(app)
